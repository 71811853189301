import { graphql } from 'gatsby';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons'
import Layout from '../components/layout';
import SEO, { SeoModel } from '../components/seo';
import moment from 'moment';
import { breaklines, linkify } from '../functions/html.functions';

export interface FacebookPostNode {
  id: string,
  fields: {
    slug: string
  },
  message: string,
  picture: string,
  full_picture: string,
  permalink_url: string,
  created_time: string,
  created_date: string,
  sday: string,
  sdate: string,
  syear: string
  smonth: string,
}

interface FacebookPostPageProps {
  location: Location,
  readonly data: {
    facebookPosts: FacebookPostNode
  },
  readonly pageContext: {
    previous?: any
    next?: any
  }
}

export const facebookPostTemplateQuery = graphql`
  query FacebookPostTemplateQuery($slug: String!) {
    facebookPosts(fields: { slug: {eq: $slug} }) {
      id
      message
      picture
      full_picture
      permalink_url
      created_time
      sday: created_time(formatString: "dddd", locale: "nl-NL")
      sdate: created_time(formatString: "DD MMMM YYYY", locale: "nl-NL")
      smonth: created_time(formatString: "MMM", locale: "nl-NL")
      syear: created_time(formatString: "YYYY", locale: "nl-NL")
    }
  }
`;

const FacebookPostTemplate: React.FC<FacebookPostPageProps> = ({
  location,
  data
}) => {
  const { facebookPosts } = data;

  const firstLineEnd = facebookPosts.message ? facebookPosts.message.indexOf('. ') : 0;
  const seo: SeoModel = {
    title: facebookPosts.message ? facebookPosts.message.substring(0, firstLineEnd === 0 ? 60 : firstLineEnd) : '',
    description: facebookPosts.message,
    imageUrl: facebookPosts.picture,
    isArticle: true,
  };

  return (
    <Layout location={location}>
      <SEO model={seo} />
      <div className="mt-5" />
      <section id="post" className={`bg-light text-center py-4`}>
        <img className="d-md-none shadow mw-100" src={`${facebookPosts.full_picture}`} />
        <div className="container px-4 text-left">
          <img className="d-none d-md-block shadow mt-5 mw-100" src={`${facebookPosts.full_picture}`} />
          <div className="mt-4 mb-5" key={`event-${facebookPosts.id}`}>
            <ul className="list-inline">
              <li className="list-inline-item">
                <FontAwesomeIcon icon={faCalendar} />
                <span className="ml-2 mr-3">{facebookPosts.sday} {facebookPosts.sdate}</span>
              </li>
              <li className="list-inline-item">
                <FontAwesomeIcon icon={faClock} />
                <span className="ml-2 mr-3">{`${moment(facebookPosts.created_time).format('HH:mm')}`}</span>
              </li>
            </ul>
            <p className="event-content" dangerouslySetInnerHTML={{__html: linkify(breaklines(facebookPosts.message))}}></p>
            <p>
              <a className="text-muted" href={facebookPosts.permalink_url} target="_blank">
                Bekijken op Facebook.
              </a>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default FacebookPostTemplate;
